import { countingActiveRequests } from './entry-setup/count-active-requests';

function getCsrfToken() {
  const meta = document.querySelector('meta[name="csrf-token"]');
  // eslint-disable-next-line no-console
  if (!meta) console.error('unable to find csrf token meta tag on page');
  return meta?.getAttribute('content');
}

function getWafToken() {
  return window.AwsWafIntegration.getToken();
}

/**
 * A more usable fetch, smaller than axios. It boasts:
 *  - automatically pulls the csrf token from <head>
 *  - retries if the error indicates a csrf failure
 *  - serializes data and passes json headers.
 *
 * If you need, we can extend it to also work with FormData,
 * but we should see if we can just submit the form, since that
 * works by default
 */
export default async function request({ url, method, data, query, retries = 2, csrfToken = getCsrfToken(), customHeaders = null }) {
  if (query) {
    // eslint-disable-next-line no-param-reassign
    url += `?${new URLSearchParams(query)}`;
  }
  const headers = {
    'accept': 'application/json',
    'x-csrf-token': csrfToken,
    'x-aws-waf-token': await getWafToken(),
    ...customHeaders,
  };
  let body;
  if (data instanceof FormData) {
    body = data;
  } else if (data && method && !['GET', 'HEAD'].includes(method.toUpperCase())) {
    body = JSON.stringify(data);
    headers['content-type'] = 'application/json';
  }
  return countingActiveRequests(() => fetch(url, {
    method,
    body,
    credentials: 'same-origin',
    headers,
  }))
  .then(async (resp) => {
    if (resp.status === 422 && retries > 0) {
      // It's likely that the CSRF token is invalid.
      // retry a couple times with the new version.
      return request({
        url,
        method,
        data,
        retries: retries - 1,
        csrfToken: resp.headers['x-csrf-token'],
      });
    }
    if (resp.status >= 400) {
      return Promise.reject(resp);
    }

    let jData = null;
    if (resp.status !== 204) {
      const respContentType = resp.headers.get('Content-Type');

      if (respContentType.includes('application/json')) {
        jData = await resp.json();
      } else if (respContentType.includes('text/html')) {
        jData = await resp.text();
      } else {
        return Promise.reject(resp);
      }
    }

    return {
      data: jData,
      status: resp.status,
      statusText: resp.statusText,
      headers: resp.headers,
    };
  });
}
